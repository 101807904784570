 <!-- 
    支付 弹框
    不要用v-if
  -->
<template>
  <!-- 支付弹框 -->
  <div>
    <el-dialog class="Open" :visible.sync="Open" :before-close="handleClose" v-if="Open">
      <div slot="title" class="price-container">
        <div class="price-name">支付金额</div>
        <div class="price-all">
          <span>￥</span>
          <span class="number">{{price}}</span>
        </div>
      </div>

      <div class="Open-secure">
        <div>
          <img src="@/assets/imgs/secure.png" alt />
          资金由平台保管
        </div>
        <span></span>
        <div>服务开启前无条件退款</div>
      </div>
      <div class="Open-code">
        <div class="Open-type">微信扫码支付</div>
        <div class="code">
          <div class="qrcode" ref="qrCodeUrl"></div>
        </div>
        <!-- <div class="code">
          <img src="@/assets/imgs/code.png" alt />
        </div>-->
        <div class="code-day">
          <div class="day">
            距离二维码过期还剩
            <!-- <span>4分55秒</span> -->
<!--            <FlipDown :endDate="residueTime(time)" :type="3" :theme="1" :timeUnit="['','小时','分','秒']"></FlipDown>-->
            <countdown-timer :end-time="residueTime(time)" class="remain-time"/>
          </div>
          <el-button type="text" icon="el-icon-refresh" @click="GetHis">点击刷新</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import QRCode from "qrcodejs2";
import CountdownTimer from "@/components/FlipDown/countdownTimer.vue";
export default {
  data() {
    return {
      Open: false,
      qrcodeSS: true,
      codeUrl: "",
      price: null, //支付金额
      time: "",
      dialogOnShow: true,
    };
  },
  components: {
    CountdownTimer,
  },
  computed: {
    // 计算订单剩余时间
    residueTime() {
      return (time) => {
        let create_time = time + 1000 * 60 * 60 * 2;
        return create_time;
      };
    },
  },
  watch: {
    Open(newVal) {
      if (newVal) {
        setTimeout(() => {
          new QRCode(this.$refs.qrCodeUrl, {
            text: this.codeUrl, // 需要转换为二维码的内容
            width: 285, // 二维码宽度
            height: 285, // 二维码高度
            colorDark: "#000000", // 二维码颜色
            colorLight: "#ffffff", // 二维码背景
          });
        }, 0);
      }
    },
  },
  methods: {
    handleClose() {
      this.Open = false;
      this.$emit("handleClose");
    },
    // 刷新页面
    GetHis() {
      this.$emit("GetHis");
    },
  },
};
</script>
<style lang="less" scoped>
// 弹框样式
/deep/ .el-dialog {
  width: 665px;
  height: 691px;
  background: #ffffff;
  border-radius: 12px 12px 12px 12px;
}
/deep/ .el-dialog__headerbtn {
  top: 45px;
  right: 30px;
}
/deep/ .el-dialog__headerbtn .el-dialog__close {
  color: #ffffff;
  font-size: 30px;
}
/deep/ .el-dialog__header {
  padding: 0 !important;
}
/deep/ .el-dialog__body {
  padding: 0 !important;
}
// /deep/ .el-dialog__headerbtn:hover .el-dialog__close {
//   color: #fc3737;
// }
// /deep/ .el-dialog__headerbtn:focus .el-dialog__close {
//   color: #fc3737;
// }
.Open-head {
  width: 100%;
  height: 122px;
  background: #fc3737;
  border-radius: 12px 12px 0px 0px;
  position: absolute;
  top: 0;
  left: 0;
  .Open-price {
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 40px;
    .price-name {
      font-weight: 400;
      color: #ffffff;
      line-height: 28px;
      font-size: 24px;
    }
    .price-all {
      font-weight: bold;
      color: #ffffff;
      line-height: 47px;
      font-size: 40px;
      margin-left: 14px;
      span {
        font-weight: 400;
        color: #ffffff;
        line-height: 28px;
        margin-right: 6px;
        font-size: 24px;
      }
    }
  }
}
.Open-line {
  height: 122px;
}
.Open-secure {
  width: 100%;
  height: 60px;
  background: #ffe6e6;
  border-radius: 0px 0px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Open-secure span {
  width: 2px;
  height: 20px;
  background: #ffc2c2;
  margin: 0 40px;
}
.Open-secure div {
  display: flex;
  align-items: center;
}
.Open-secure div img {
  width: 12px;
  height: 15px;
  margin-right: 8px;
}
.Open-code {
  .Open-type {
    font-weight: 400;
    color: #000000;
    line-height: 38px;
    font-size: 32px;
    margin-top: 35px;
    text-align: center;
  }
  .code {
    width: 297px;
    height: 297px;
    margin: 38px auto 0;
    border: 1px solid #999999;
    padding: 2px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    .show_img {
      width: 100%;
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .code-day {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    .day {
      display: flex;
      align-items: center;
      font-weight: 400;
      color: #000000;
      line-height: 23px;
      font-size: 20px;
      margin-right: 40px;
      .remain-time {
        color: #fc3737;
      }
    }
    /deep/ .el-button {
      font-size: 18px;
    }
  }
}

.price-container {
  display: flex;
  align-items: center;
  height: 120px;
  background-color: #fc3737;
  color: #ffffff;
  font-size: 24px;
  padding: 0 80px;
  border-radius: 12px 12px 0 0;

  .price-all {
    display: flex;
    align-items: baseline;
    margin-left: 30px;
    span {
      font-size: 24px;
      &.number {
        font-size: 40px;
        font-weight: 600;
      }
    }
  }

}
</style>